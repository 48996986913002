var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { fetchDoubleRadar } from './api';
import { data } from './data';
const labels = ['N/A', 'Below', 'Meets', 'Exceeds'];
function renderCustomPolarRadiusAxis(props) {
    const { label, payload, cx, cy } = props, rest = __rest(props, ["label", "payload", "cx", "cy"]);
    return (React.createElement("text", Object.assign({ cx: cx, cy: cy }, rest),
        React.createElement("tspan", { className: "label" }, label)));
}
const renderColorfulLegendText = (value) => {
    return React.createElement("span", { style: { color: '#000' } }, value);
};
export const RadarFeed = ({ id }) => {
    window.caseid = id;
    const [feed, setFeed] = useState([]);
    useEffect(() => {
        fetchDoubleRadar().then((data) => {
            setFeed(data.data);
        }, () => setFeed([])).catch((err) => console.error(err));
    }, []);
    return (React.createElement("div", { className: "container" },
        React.createElement(RadarChart, { outerRadius: 150, width: 400, height: 400, data: feed || data },
            React.createElement(PolarGrid, { innerRadius: 100 }),
            React.createElement(PolarAngleAxis, { dataKey: "subject" }),
            React.createElement(PolarRadiusAxis, { domain: [0, 3], angle: 90, tickCount: 4, tick: (_a) => {
                    var { index } = _a, value = __rest(_a, ["index"]);
                    return renderCustomPolarRadiusAxis(Object.assign(Object.assign({}, value), { label: labels[index] }));
                } }),
            React.createElement(Radar, { name: "Feedback sender", dataKey: "A", fill: "#5f8cfc", fillOpacity: 0.4 }),
            React.createElement(Radar, { name: "Self-assessment", dataKey: "B", fill: "#a5a7a9", fillOpacity: 0.6 }),
            React.createElement(Legend, { formatter: renderColorfulLegendText }))));
};
export default RadarFeed;
