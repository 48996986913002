var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCurrentCaseId } from "./helpers";
const HOST_DEV = "https://tk.k-expert.com:8085", API_PREFIX = "prweb/api/icpapi/v1/", LOCAL_URL = 'http://localhost:8080', ORIGIN = window.location.origin, API_URL = ORIGIN === LOCAL_URL || HOST_DEV ? `${HOST_DEV}/${API_PREFIX}` : `${ORIGIN}/${API_PREFIX}`, RADAR_URL = `${API_URL}/getSingleRaddarChartData`, DOUBLE_RADAR = `${API_URL}/getDoubleRaddarChartData`;
export const fetchRadarFeed = () => __awaiter(void 0, void 0, void 0, function* () {
    const caseId = getCurrentCaseId();
    return fetch(`${RADAR_URL}/${caseId}`).
        then(response => response.json()).catch(err => err);
});
export const fetchDoubleRadar = () => __awaiter(void 0, void 0, void 0, function* () {
    const caseId = getCurrentCaseId();
    return fetch(`${DOUBLE_RADAR}/${caseId}`).
        then(response => response.json()).catch(err => err);
});
