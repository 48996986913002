export const data = [
    {
        subject: '1',
        A: 1,
        fullMark: 3,
    },
    {
        subject: '2',
        A: 2,
        fullMark: 3,
    },
    {
        subject: '3',
        A: 0,
        fullMark: 3,
    },
    {
        subject: '4',
        A: 2,
        fullMark: 3,
    },
    {
        subject: '5',
        A: 2,
        fullMark: 3,
    },
    {
        subject: '6',
        A: 3,
        fullMark: 3,
    },
    {
        subject: '7',
        A: 1,
        fullMark: 3,
    },
    {
        subject: '8',
        A: 3,
        fullMark: 3,
    },
    {
        subject: '9',
        A: 2,
        fullMark: 3,
    },
    {
        subject: '10',
        A: 3,
        fullMark: 3,
    },
    {
        subject: '11',
        A: 3,
        fullMark: 3,
    },
    {
        subject: '12',
        A: 0,
        fullMark: 3,
    },
    {
        subject: '13',
        A: 1,
        fullMark: 3,
    },
    {
        subject: '14',
        A: 2,
        fullMark: 3,
    },
    {
        subject: '15',
        A: 3,
        fullMark: 3,
    },
];
